import { useEffect, useState } from "react";
import selectIcon from "./utils/selectIcon";
import { makeStyles } from "@material-ui/core/styles";
import { primaryConfig, categoriesConfig } from './config/sidebar'

import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Link from "./Link";

import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  sidebarDrawerContainer: {
    height: "100%",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    overflowY: "auto",
  },
  drawerSidebar: {
    width: 60,
    transition: "width 0.25s",
    flexShrink: 0,
  },
  drawerSidebarFull: {
    width: 262,
    transition: "width 0.25s",
    flexShrink: 0,
  },
  drawerPaperSidebar: {
    width: 60,
    transition: "width 0.25s",
    overflow: "hidden",
    borderRight: "none",
  },
  drawerPaperSidebarFull: {
    width: 262,
    transition: "width 0.25s",
    overflow: "hidden",
    borderRight: "none",
  },
  menuButton: {
    marginLeft: -18,
    [theme.breakpoints.up("md")]: {
      marginRight: 18,
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      flexGrow: 0.5,
    },
    "& img": {
      display: "inline-block",
      marginTop: 7,
    },
  },
  drawerToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
  },
  nestedList: {
    marginLeft: 15,
  },
  label: {
    whiteSpace: "nowrap"
  }
}));

const SidebarListItem = ({
  current,
  id,
  label,
  href,
  icon,
  target,
  subcategories,
  sidebarToggle,
  setSidebarToggle,
  layoutMode,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (layoutMode === "Sidebar") {
    // handle tablet screen expansion
      if (!sidebarToggle) {
        setSidebarToggle(!sidebarToggle);
      }
    } else {
    // handle desktop expansion
      if (sidebarToggle) {
        setSidebarToggle(!sidebarToggle);
      }
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (sidebarToggle) {
      setOpen(false);
    }
    if (layoutMode === "Sidebar") {
      setOpen(false);
    }
  }, [sidebarToggle, layoutMode]);

  if (subcategories) {
    return (
      <>
        <ListItem
          button
          selected={current === id}
          key={id}
          component="a"
          href={href}
          target={target}
          onClick={handleClick}
        >
          <ListItemIcon>{selectIcon(icon)}</ListItemIcon>
          <ListItemText primary={label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {subcategories && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.nestedList}>
              {subcategories.map(({ id, label, href, icon }) => {
                return (
                  <SidebarListItem
                    button
                    key={id}
                    current={current}
                    id={id}
                    label={label}
                    href={href}
                    icon={icon}
                  />
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  } else {
    return (
      <ListItem
        button
        selected={current === id}
        key={id}
        component="a"
        href={href}
        target={target}
        onClick={handleClick}
      >
        <ListItemIcon>{selectIcon(icon)}</ListItemIcon>
        <ListItemText primary={label} className={classes.label}/>
      </ListItem>
    );
  }
};

const SidebarNav = ({
  current,
  authenticated,
  menuHamburgerAnchor,
  layoutClass,
  layoutMode,
  handleHamburgerClose,
  sidebarToggle,
  setSidebarToggle,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      variant={layoutMode === "Full" ? "temporary" : "permanent"}
      open={layoutMode === "Full" ? Boolean(menuHamburgerAnchor) : true}
      className={classes[layoutClass("drawer")]}
      onClose={handleHamburgerClose}
      classes={{
        paper: classes[layoutClass("drawerPaper")],
      }}
    >
      <Toolbar variant="dense" className={classes.drawerToolbar}>
        <IconButton
          className={classes.menuButton}
          edge="start"
          aria-label="menu"
          onClick={handleHamburgerClose}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.title}>
          <Link href="/">
            <img
              alt="Better than Vegas"
              src="/logo-btv-alt.webp"
              srcSet="/logo-btv-alt.webp 1x, /logo-btv-alt@2x.webp 2x"
            />
          </Link>
        </Typography>
      </Toolbar>
      <div
        className={
          layoutMode === "Full" ? null : classes.sidebarDrawerContainer
        }
      >
        <List>
          {primaryConfig.map(({ id, label, href, fallbackHref, target, icon }, index) => {
            let dest;
            if (fallbackHref) dest = authenticated ? href : fallbackHref;
            dest = href;
            return (
              <SidebarListItem
                key={index}
                current={current}
                id={id}
                label={label}
                href={dest}
                fallbackHref={fallbackHref}
                target={target}
                icon={icon}
              />
            );
          })}
        </List>
        <Divider />
        <List
          subheader={
            layoutClass("") !== "Sidebar" ? (
              <ListSubheader>
                <Typography variant="button" color="textPrimary">
                  Categories
                </Typography>
              </ListSubheader>
            ) : null
          }
        >
          {categoriesConfig.map(({ id, label, href, subcategories, icon }) => {
            return (
              <SidebarListItem
                key={id}
                current={current}
                id={id}
                label={label}
                href={href}
                icon={icon}
                subcategories={subcategories}
                setSidebarToggle={setSidebarToggle}
                sidebarToggle={sidebarToggle}
                layoutClass={layoutClass}
                layoutMode={layoutMode}
              />
            );
          })}
        </List>
      </div>
    </Drawer>
  );
};

export default SidebarNav;
