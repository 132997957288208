import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import SportsFootballOutlinedIcon from '@material-ui/icons/SportsFootballOutlined';
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";
import SportsMotorsportsIcon from "@material-ui/icons/SportsMotorsports";
import SportsGolfIcon from "@material-ui/icons/SportsGolf";
import SportsIcon from "@material-ui/icons/Sports";
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import SportsTennisIcon from '@material-ui/icons/SportsTennis';
import SportsGloveIcon from '@material-ui/icons/SportsMma';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import SportsVolleyballIcon from '@material-ui/icons/SportsVolleyball';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import SportsRugbyIcon from "@material-ui/icons/SportsRugby";
import TargetIcon from "@material-ui/icons/Adjust";
import BikeIcon from "@material-ui/icons/DirectionsBike";
import HomeIcon from "@material-ui/icons/Home";

import { ICON_TYPES } from '../config/sidebar' 

const selectIcon = (name) => {
  switch(name) {
  case ICON_TYPES.HOCKEY:
    return ( <SportsHockeyIcon /> );
  case ICON_TYPES.BASEBALL:
    return ( <SportsBaseballIcon /> );
  case ICON_TYPES.BASKETBALL:
    return ( <SportsBasketballIcon /> );
  case ICON_TYPES.BOXING: 
    return ( <SportsGloveIcon /> );
  case ICON_TYPES.CRICKET: 
    return ( <SportsCricketIcon /> );
  case ICON_TYPES.CYCLING:
    return ( <BikeIcon /> );
  case ICON_TYPES.DARTS:
    return ( <TargetIcon /> );
  case ICON_TYPES.ESPORTS: 
    return ( <SportsEsportsIcon /> );
  case ICON_TYPES.FOOTBALL:
    return ( <SportsFootballIcon /> );
  case "FootballAlt":
    return ( <SportsFootballOutlinedIcon /> );
  case ICON_TYPES.RUGBY:
    return ( <SportsRugbyIcon /> );
  case ICON_TYPES.SOCCER:
    return ( <SportsSoccerIcon /> );
  case ICON_TYPES.SUBSCRIPTIONS:
    return ( <SubscriptionsIcon /> );
  case ICON_TYPES.MOTORSPORTS:
    return ( <SportsMotorsportsIcon /> );
  case ICON_TYPES.GOLF:
    return ( <SportsGolfIcon /> );
  case ICON_TYPES.HANDBALL:
    return ( <SportsHandballIcon /> );
  case ICON_TYPES.HORSERACING:
    return ( <ConfirmationNumberIcon /> );
  case ICON_TYPES.HOME:
    return ( <HomeIcon /> );
  case ICON_TYPES.MMA:
    return ( <SportsKabaddiIcon /> );
  case ICON_TYPES.TENNIS:
    return ( <SportsTennisIcon /> );
  case ICON_TYPES.MONEY:
    return ( <AttachMoneyIcon />);
  case ICON_TYPES.VOLLEYBALL:
    return ( <SportsVolleyballIcon />);
  default:
    return ( <SportsIcon/> );
  }
};

export default selectIcon;
