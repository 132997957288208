module.exports = {
  set: function(value) {
    // client side only
    if (typeof(document) !== 'undefined') {
      document.cookie = `flash=${encodeURIComponent(value)}`;
    }
  },
  get: function() {
    let value = null;
    // client side only
    if (typeof(document) !== 'undefined') {
      let cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].split('=');
        if (cookie[0].trim() === 'flash') {
          value = decodeURIComponent(cookie[1].trim());
          document.cookie = `flash=null; expires=${new Date(+new Date - 1).toUTCString()}`;
          break;
        }
      }
    }
    return value;
  }
}
