import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import flash from "./utils/flash";
import withAuth from "./utils/withAuth";
import { auth } from "./utils/firebase";
import router from "next/router";
// import Image from "next/image"; // need to upgrade to v12
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "./Link";
import Search from "./Search";
import SidebarNav from "./SidebarNav";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import UploadFormNoSSR from "./UploadFormNoSSR";
import VideoCallIcon from "@material-ui/icons/VideoCall";

const useStyles = makeStyles((theme) => ({
  sidebarRoot: {
    display: "flex",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    maxWidth: "100%",
    transition: "max-width 0.25s",
  },
  contentSidebar: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    flexGrow: 1,
    maxWidth: "calc(100% - 60px)",
    transition: "max-width 0.25s",
  },
  contentSidebarFull: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    flexGrow: 1,
    maxWidth: "calc(100% - 262px)",
    transition: "max-width 0.25s",
  },
  menuButton: {
    marginLeft: -18,
    [theme.breakpoints.up("md")]: {
      marginRight: 18,
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      flexGrow: 0.5,
    },
    "& img": {
      display: "inline-block",
      marginTop: 7,
    },
  },
  headerAvatar: {
    width: 24,
    height: 24,
  },
  rightMenu: {
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
      textAlign: "right",
    },
  },
  signIn: {
    marginLeft: 10,
    whiteSpace: "nowrap",
  },
  signInIcon: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "inline-block",
      marginRight: 4,
    },
  },
  main: {
    flex: 1,
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    paddingTop: 32,
    paddingBottom: 32,
  },
  footerLeft: {
    [theme.breakpoints.up("sm")]: {
      "& p ": {
        marginTop: 25,
      },
    },
  },
  footerRight: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  const alert = flash.get();

  const { full, current } = props;

  let layoutMode = "Full";

  const large = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const medium = useMediaQuery((theme) => theme.breakpoints.up("md"));

  if (!full) {
    if (large) {
      layoutMode = "SidebarFull";
    } else if (medium) {
      layoutMode = "Sidebar";
    }
  }

  const [menuAvatarAnchor, setMenuAvatarAnchor] = useState(null);
  const [menuHamburgerAnchor, setMenuHamburgerAnchor] = useState(null);
  const [sidebarToggle, setSidebarToggle] = useState(false);

  const layoutClass = (base) => {
    switch (layoutMode) {
      case "Full":
        return base;
      case "Sidebar":
        return base + (sidebarToggle ? "SidebarFull" : "Sidebar");
      case "SidebarFull":
        return base + (sidebarToggle ? "Sidebar" : "SidebarFull");
      default:
        return null;
    }
  };

  const handleAvatarClick = (e) => {
    setMenuAvatarAnchor(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setMenuAvatarAnchor(null);
  };

  const handleHamburgerClick = (e) => {
    setMenuHamburgerAnchor(e.currentTarget);
  };

  const handleHamburgerClose = () => {
    switch (layoutMode) {
      case "Full":
        setMenuHamburgerAnchor(null);
        break;
      case "Sidebar":
      case "SidebarFull":
        setSidebarToggle(!sidebarToggle);
        break;
    }
  };

  let authenticated = props.auth.authenticated;

  const logout = () => auth.signOut();

  return (
    <div className={layoutMode === "Full" ? null : classes.sidebarRoot}>
      <AppBar component="nav">
        <Toolbar variant="dense">
          <IconButton
            className={classes.menuButton}
            edge="start"
            aria-label="menu"
            onClick={handleHamburgerClick}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link href="/">
              <img
                alt="Better than Vegas"
                src="/logo-btv-alt.webp"
                srcSet="/logo-btv-alt.webp 1x, /logo-btv-alt@2x.webp 2x"
              />
            </Link>
          </Typography>
          <Search authenticated={authenticated} />
          {!authenticated && (
            <div className={classes.rightMenu}>
              <Button
                color="default"
                startIcon={
                  <VideoCallIcon
                    style={{ color: "#fff", fontSize: 28, marginRight: 0 }}
                  />
                }
                href="/login"
              >
                {props.label}
              </Button>

              <Button
                variant="outlined"
                className={classes.signIn}
                component="a"
                href="/login"
                color="inherit"
              >
                <PersonIcon className={classes.signInIcon} />
                Sign in
              </Button>
            </div>
          )}
          {authenticated && (
            <div className={classes.rightMenu}>
              <UploadFormNoSSR label="+" icon="VideoCallIcon" />

              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar-avatar"
                aria-haspopup="true"
                onClick={handleAvatarClick}
                color="inherit"
              >
                <Avatar
                  src={
                    (props.auth.userData && props.auth.userData.photoURL) ||
                    "/avatar-blue.png"
                  }
                  className={classes.headerAvatar}
                />
              </IconButton>

              <Menu
                id="menu-appbar-avatar"
                anchorEl={menuAvatarAnchor}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(menuAvatarAnchor)}
                onClose={handleAvatarClose}
              >
                <MenuItem
                  onClick={() => {
                    router.push(`/profile/${props.auth.userData.username}`);
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    router.push("/profile");
                  }}
                >
                  Account Settings
                </MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <SidebarNav
        current={current}
        authenticated={authenticated}
        menuHamburgerAnchor={menuHamburgerAnchor}
        layoutClass={layoutClass}
        layoutMode={layoutMode}
        handleHamburgerClose={handleHamburgerClose}
        setSidebarToggle={setSidebarToggle}
        sidebarToggle={sidebarToggle}
      />

      <div className={classes[layoutClass("content")]}>
        <Toolbar variant="dense" />
        {alert && (
          <Container maxWidth="lg">
            {alert && (
              <Alert
                elevation={6}
                variant="filled"
                style={{ marginBottom: 30 }}
              >
                {alert}
              </Alert>
            )}
          </Container>
        )}
        <main className={classes.main}>{props.children}</main>
        <footer className={classes.footer}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className={classes.footerLeft}>
                <Typography variant="body2">
                  Copyright 2020 - betterthan.vegas -{" "}
                  <Link href="/privacy" style={{ color: "white" }}>
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.footerRight}>
                <img
                  alt="Better than Vegas"
                  src="/logo-btv.webp"
                  srcSet="/logo-btv.webp 1x, /logo-btv@2x.webp 2x"
                />
              </Grid>
            </Grid>
          </Container>
        </footer>
      </div>
    </div>
  );
};

export default withAuth(Layout);
