const ICON_TYPES = {
  BASEBALL: "Baseball",
  BASKETBALL: "Basketball",
  BOXING: "Boxing",
  CRICKET: "Cricket",
  CYCLING: "Cycling",
  DARTS: "Darts",
  ESPORTS: "eSports",
  FOOTBALL: "Football",
  GOLF: "Golf",
  HANDBALL: "Handball",
  HOCKEY: "Hockey",
  HOME: "Home",
  MMA: "MMA",
  MONEY: "Money",
  HORSERACING: "HorseRacing",
  MOTORSPORTS: "Motorsports",
  RUGBY: "Rugby",
  SOCCER: "Soccer",
  SUBSCRIPTIONS: "Subscriptions",
  TABLE_TENNIS: "TableTennis",
  TENNIS: "Tennis",
  VOLLEYBALL: "Volleyball",
};

const primaryConfig = [
    { id: "home",
      label: "Home",
      href: "/",
      icon: ICON_TYPES.HOME
    },
    {
      id: "",
      label: "Shop BTV Merch",
      href: "https://better-than-vegas.myshopify.com/",
      target: "_blank",
      icon: ICON_TYPES.MONEY,
    },
    // {
    //   id: "",
    //   label: "Weekly Showdown",
    //   href: "https://get.betterthan.vegas/btv-showdown/",
    //   target: "_blank",
    //   icon: ICON_TYPES.MONEY,
    // },
    {
      id: "subscriptions",
      label: "Subscriptions",
      href: "/subscriptions",
      fallbackHref: "/login",
      icon: ICON_TYPES.SUBSCRIPTIONS,
    },
  ];

const categoriesConfig = [
  {
    id: "baseball",
    label: "Baseball",
    icon: ICON_TYPES.BASEBALL,
    subcategories: [
      {
        id: "mlb",
        label: "MLB",
        href: "/tags/mlb",
        icon: ICON_TYPES.BASEBALL,
      },
    ],
  },
  {
    id: "basketball",
    label: "Basketball",
    icon: ICON_TYPES.BASKETBALL,
    subcategories: [
      {
        id: "nba",
        label: "NBA",
        href: "/tags/nba",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "ncaamb",
        label: "NCAAB Men's",
        href: "/tags/ncaamb",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "ncaawb",
        label: "NCAAB Women's",
        href: "/tags/ncaawb",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "wnba",
        label: "WNBA",
        href: "/tags/wnba",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "euro-league",
        label: "Euroleague",
        href: "/tags/euroleague",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "australia-nbl",
        label: "Australia - NBL",
        href: "/tags/australia-nbl",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "brazil-nbb",
        label: "Brazil - NBB",
        href: "/tags/brazil-nbb",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "poland-ebl",
        label: "Poland - EBL",
        href: "/tags/poland-ebl",
        icon: ICON_TYPES.BASKETBALL,
      },
      {
        id: "spain-lacb",
        label: "Spain - Liga ACB",
        href: "/tags/spain-lacb",
        icon: ICON_TYPES.BASKETBALL,
      },
    ],
  },
  {
    id: "boxing",
    label: "Boxing",
    href: "/tags/boxing",
    icon: ICON_TYPES.BOXING,
  },
  {
    id: "cricket",
    label: "Cricket",
    icon: ICON_TYPES.CRICKET,
    subcategories: [
      {
        id: "ipl",
        label: "Indian Premier League",
        href: "tags/ipl",
        icon: ICON_TYPES.CRICKET,
      },
    ],
  },
  {
    id: "cycling",
    label: "Cycling",
    href: "/tags/cycling",
    icon: ICON_TYPES.CYCLING,
  },
  {
    id: "darts",
    label: "Darts",
    icon: ICON_TYPES.DARTS,
    subcategories: [
      {
        id: "pdc",
        label: "Professional Darts Corporation",
        href: "/tags/pdc",
        icon: ICON_TYPES.DARTS,
      },
      {
        id: "pld",
        label: "Premier League of Darts",
        href: "/tags/pld",
        icon: ICON_TYPES.DARTS,
      },
    ],
  },
  {
    id: "esports",
    label: "eSports",
    href: "/tags/esports",
    icon: ICON_TYPES.ESPORTS,
  },
  {
    id: "football",
    label: "Football",
    icon: ICON_TYPES.FOOTBALL,
    subcategories: [
      {
        id: "cfl",
        label: "CFL",
        href: "/tags/cfl",
        icon: ICON_TYPES.FOOTBALL,
      },
      {
        id: "nfl",
        label: "NFL",
        href: "/tags/nfl",
        icon: ICON_TYPES.FOOTBALL,
      },
      {
        id: "ncaaf",
        label: "NCAAF",
        href: "/tags/ncaaf",
        icon: ICON_TYPES.FOOTBALL,
      },
    ],
  },
  {
    id: "golf",
    label: "Golf",
    icon: ICON_TYPES.GOLF,
    subcategories: [
      {
        id: "pga",
        label: "PGA",
        href: "/tags/pga",
        icon: ICON_TYPES.GOLF,
      },
      {
        id: "lpga",
        label: "LPGA",
        href: "/tags/lpga",
        icon: ICON_TYPES.GOLF,
      },
    ],
  },
  {
    id: "handball",
    label: "Handball",
    icon: ICON_TYPES.HANDBALL,
    subcategories: [
      {
        id: "handbollsligan",
        label: "Sweden - Handbollsligan",
        href: "/tags/handbollsligan",
        icon: ICON_TYPES.HANDBALL,
      },
      {
        id: "handball-champions-league",
        label: "Champions League",
        href: "/tags/handball-champions-league",
        icon: ICON_TYPES.HANDBALL,
      },
      {
        id: "lnh-d1",
        label: "France - LNH D1",
        href: "/tags/lnh-d1",
        icon: ICON_TYPES.HANDBALL,
      },
      {
        id: "handball-bundesliga",
        label: "Germany - Bundesliga",
        href: "/tags/handball-bundesliga",
        icon: ICON_TYPES.HANDBALL,
      },
    ],
  },
  {
    id: "hockey",
    label: "Hockey",
    icon: ICON_TYPES.HOCKEY,
    subcategories: [
      {
        id: "nhl",
        label: "NHL",
        href: "/tags/nhl",
        icon: ICON_TYPES.HOCKEY,
      },
      {
        id: "khl",
        label: "KHL",
        href: "/tags/khl",
        icon: ICON_TYPES.HOCKEY,
      },
      {
        id: "nla",
        label: "Switzerland - NLA",
        href: "/tags/nla",
        icon: ICON_TYPES.HOCKEY,
      },
      {
        id: "liiga",
        label: "Finland - Liiga",
        href: "/tags/liiga",
        icon: ICON_TYPES.HOCKEY,
      },
    ],
  },
  {
    id: "mma",
    label: "MMA",
    icon: ICON_TYPES.MMA,
    subcategories: [
      {
        id: "ufc",
        label: "UFC",
        href: "tags/ufc",
        icon: ICON_TYPES.MMA,
      },
      {
        id: "bellator",
        label: "Bellator",
        href: "tags/bellator",
        icon: ICON_TYPES.MMA,
      },
    ],
  },
  {
    id: "horse-racing",
    label: "Horse Racing",
    href: "/tags/horse-racing",
    icon: ICON_TYPES.HORSERACING,
  },
  {
    id: "motorsports",
    label: "Motorsports",
    icon: ICON_TYPES.MOTORSPORTS,
    subcategories: [
      {
        id: "nascar",
        label: "NASCAR",
        href: "/tags/nascar",
        icon: ICON_TYPES.MOTORSPORTS,
      },
      {
        id: "f1-futures",
        label: "F1 Futures",
        href: "/tags/f1-futures",
        icon: ICON_TYPES.MOTORSPORTS,
      },
    ],
  },
  {
    id: "rugby-league",
    label: "Rugby League",
    icon: ICON_TYPES.RUGBY,
    subcategories: [
      {
        id: "super-league",
        label: "Super League",
        href: "/tags/super-league",
        icon: ICON_TYPES.RUGBY,
      },
      {
        id: "nrl",
        label: "NRL",
        href: "/tags/NRL",
        icon: ICON_TYPES.RUGBY,
      },
      {
        id: "rugby-union",
        label: "Rugby Union",
        href: "/tags/rugby-union",
        icon: ICON_TYPES.RUGBY,
      },
    ],
  },
  {
    id: "soccer",
    label: "Soccer",
    icon: ICON_TYPES.SOCCER,
    subcategories: [
      {
        id: "england-premiership",
        label: "England - Premiership",
        href: "/tags/england-premiership",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "pro-14",
        label: "Pro 14",
        href: "/tags/pro-14",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "top-14",
        label: "France - Top 14 Soccer",
        href: "/tags/top-14",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "azerbaijan-premier-league",
        label: "Azerbaijan - Premier League",
        href: "/tags/azerbaijan-premier-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "argentina-arimera-division",
        label: "Argentina - Primera Division",
        href: "/tags/argentina-arimera-division",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "australia-a-league",
        label: "Australia - Primera Division",
        href: "/tags/australia-a-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "austria-bundesliga",
        label: "Austria - Bundesliga",
        href: "/tags/austria-bundesliga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "austria-erste-liga",
        label: "Austria - Erste Liga",
        href: "/tags/austria-erste-liga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "belgium-jupiler-pro-league",
        label: "Belgium - Jupiler Pro League",
        href: "/tags/belgium-jupiler-pro-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "bulgaria-pfl-1",
        label: "Bulgaria - PFL 1",
        href: "/tags/bulgaria-pfl-1",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "champions-league",
        label: "Champions League",
        href: "/tags/champions-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "costa-rica-primera",
        label: "Costa Rica - Primera Division",
        href: "/tags/costa-rica-primera",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "croatia-hnl",
        label: "Croatia - 1. HNL League",
        href: "/tags/croatia-hnl",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "czech-republic-first-league",
        label: "Czech Republic - First League",
        href: "/tags/czech-republic-first-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "denmark-first-division",
        label: "Denmark - 1st Division",
        href: "/tags/denmark-first-division",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "denmark-superligaen",
        label: "Denmark - Superligaen",
        href: "/tags/denmark-superligaen",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "england-championship",
        label: "England - Championship",
        href: "/tags/england-championship",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "england-fa-cup",
        label: "England - FA Cup",
        href: "/tags/england-fa-cup",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "england-league-one",
        label: "England - League One",
        href: "/tags/england-league-one",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "england-league-two",
        label: "England - League Two",
        href: "/tags/england-league-two",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "premier-league",
        label: "England - Premiere League",
        href: "/tags/premier-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "europa-league",
        label: "Europa League",
        href: "/tags/europa-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "ligue-1",
        label: "France - Ligue 1",
        href: "/tags/ligue-1",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "ligue-2",
        label: "France - Ligue 2",
        href: "/tags/ligue-2",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "bundesliga",
        label: "Germany - Bundesliga",
        href: "/tags/bundesliga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "germany-dfb",
        label: "Germany - DFB-Pokal",
        href: "/tags/germany-dfb",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "greece-superleague",
        label: "Greece - Super League",
        href: "/tags/greece-superleague",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "hungary-nb1",
        label: "Hungary - NB 1",
        href: "/tags/hungary-nb1",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "hungary-nb2",
        label: "Hungary - NB 2",
        href: "/tags/hungary-nb2",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "israel-ligat-haal",
        label: "Israel - Ligat Ha'Al",
        href: "/tags/israel-ligat-haal",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "japan-j1",
        label: "Japan - J1-League",
        href: "/tags/japan-j1",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "serie-a",
        label: "Serie A",
        href: "/tags/serie-a",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "serie-b",
        label: "Serie B",
        href: "/tags/serie-b",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "mexico-liga-mx",
        label: "Mexico - Liga MX",
        href: "/tags/mexico-liga-mx",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "mls",
        label: "MLS",
        href: "/tags/mls",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "netherlands-eerste-divisie",
        label: "Netherlands - Eerste Divisie",
        href: "/tags/netherlands-eerste-divisie",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "netherlands-eredivisie",
        label: "Netherlands - Eredivisie",
        href: "/tags/netherlands-eredivisie",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "netherlands-knvb-beker",
        label: "Netherlands - KNVB Beker",
        href: "/tags/netherlands-knvb-beker",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "northern-ireland-nifl",
        label: "Northern Ireland - NIFL Premiership",
        href: "/tags/northern-ireland-nifl",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "norway-eliteserien",
        label: "Norway - Eliteserien",
        href: "/tags/norway-eliteserien",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "norway-obos-ligaen",
        label: "Norway - OBOS-ligaen",
        href: "/tags/norway-obos-ligaen",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "poland-ekstraklasa",
        label: "Poland - Ekstraklasa",
        href: "/tags/poland-ekstraklasa",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "poland-puchar-polski",
        label: "Poland - Puchar Polski",
        href: "/tags/poland-puchar-polski",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "portugal-ligapro",
        label: "Portugal - LigaPro",
        href: "/tags/portugal-ligapro",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "romania-liga-1",
        label: "Romania - Liga I",
        href: "/tags/romania-liga-1",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "romania-liga-2",
        label: "Romania - Liga II",
        href: "/tags/romania-liga-2",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "russia-premiere-league",
        label: "Russia - Premier League",
        href: "/tags/russia-premiere-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "scotland-championship",
        label: "Scotland - Championship",
        href: "/tags/scotland-championship",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "scotland-scottish-premiership",
        label: "Scotland - Scottish Premiership",
        href: "/tags/scotland-scottish-premiership",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "serbia-super-liga",
        label: "Serbia - Super Liga",
        href: "/tags/serbia-super-liga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "slovakia-super-liga",
        label: "Slovakia - Super Liga",
        href: "/tags/slovakia-super-liga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "slovenia-prva-liga",
        label: "Slovenia - Prva Liga",
        href: "/tags/slovenia-prva-liga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "south-korea-k-league",
        label: "South Korea - K-League",
        href: "/tags/south-korea-k-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "south-korea-k-league",
        label: "South Korea - K-League",
        href: "/tags/south-korea-k-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "laliga",
        label: "Spain - La Liga",
        href: "/tags/laliga",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "laliga-2",
        label: "Spain - La Liga 2",
        href: "/tags/laliga-2",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "sweden-allsvenskan",
        label: "Sweden - Allsvenskan",
        href: "/tags/sweden-allsvenskan",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "sweden-superettan",
        label: "Sweden - Superettan",
        href: "/tags/sweden-superettan",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "switzerland-challenge-league",
        label: "Switzerland - Challenge League",
        href: "/tags/switzerland-challenge-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "switzerland-super-league",
        label: "Switzerland - Super League",
        href: "/tags/switzerland-super-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "turkey-super-lig",
        label: "Turkey - Super Lig",
        href: "/tags/turkey-super-lig",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "uefa-nations-league",
        label: "UEFA Nations League",
        href: "/tags/uefa-nations-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "uruguay-primera-division",
        label: "Uruguay - Primera Division",
        href: "/tags/uruguay-primera-division",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "wales-premier-league",
        label: "Wales - Premier League",
        href: "/tags/wales-premier-league",
        icon: ICON_TYPES.SOCCER,
      },
      {
        id: "world-cup-2022",
        label: "World Cup 2022",
        href: "/tags/world-cup-2022",
        icon: ICON_TYPES.SOCCER,
      },
    ],
  },
  {
    id: "table-tennis",
    label: "Table Tennis",
    icon: ICON_TYPES.TABLE_TENNIS,
    subcategories: [
      {
        id: "moscow-liga-pro",
        label: "Moscow Liga Pro",
        href: "/tags/moscow-liga-pro",
        icon: ICON_TYPES.TABLE_TENNIS,
      },
    ],
  },
  {
    id: "tennis",
    label: "Tennis",
    icon: ICON_TYPES.TENNIS,
    subcategories: [
      {
        id: "tennis-atp",
        label: "ATP",
        href: "/tags/tennis-atp",
        icon: ICON_TYPES.TENNIS,
      },
      {
        id: "tennis-wta",
        label: "WTA",
        href: "/tags/tennis-wta",
        icon: ICON_TYPES.TENNIS,
      },
    ],
  },
  {
    id: "volleyball",
    label: "Volleyball",
    icon: ICON_TYPES.VOLLEYBALL,
    subcategories: [
      {
        id: "russia-super-league",
        label: "Russia - Super League",
        href: "/tags/russia-super-league",
        icon: ICON_TYPES.VOLLEYBALL,
      },
      {
        id: "turkey-efelerligi",
        label: "Turkey - Efelerligi",
        href: "/tags/turkey-efelerligi",
        icon: ICON_TYPES.VOLLEYBALL,
      },
    ],
  },
];

module.exports = { primaryConfig, categoriesConfig, ICON_TYPES }
