import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { db } from '../src/utils/firebase';
import keywords from "../src/utils/keywords";
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import router from "next/router";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    marginLeft: 0,
    width: 'auto',        
    '& .MuiAutocomplete-root.Mui-focused + div': {
      color: '#ccc'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      color: '#ccc'
    }
  },
  endAdornment: {
    position: 'absolute',
    right: 0,
  },
  searchInputInputWithAuth: {
    '&:focus, &:not(:placeholder-shown)': {
      width: 194,
    }
  },
  searchInputInputWithoutAuth: {
    '&:focus, &:not(:placeholder-shown)': {
      width: 148,
    }
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    borderRadius: 18,
    transition: theme.transitions.create('width'),
    minWidth: 0,
    width: 0,
    '&:focus, &:not(:placeholder-shown)': {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      backgroundColor: theme.palette.background.default,
      width: 240,
      '&:focus, &:not(:placeholder-shown)': {
        width: 240,
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 440,
      '&:focus, &:not(:placeholder-shown)': {
        width: 440,
      }
    },
    [theme.breakpoints.up('lg')]: {
      width: 640,
      '&:focus, &:not(:placeholder-shown)': {
        width: 640,
      }
    }
  },
}));

const Search = (props) => {
  const classes = useStyles();

  const { authenticated } = props;

  // search
  const [search, setSearch] = React.useState(null);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [searchOptions, setSearchOptions] = React.useState([]);
  const [searchOptionsOpen, setSearchOptionsOpen] = React.useState(false);
  const [searchOptionsLoaded, setSearchOptionsLoaded] = React.useState(false);
  const searchOptionsLoading = searchOptionsOpen &&
        searchOptions.length === 0 &&
        keywords(searchInputValue).length > 0 &&
        searchOptionsLoaded === false;


  React.useEffect(() => {
    let active = true;

    if (!searchOptionsLoading) {
      return undefined;
    }

    (async() => {
      const result = await db.collection("tags")
            .where("keywords", "array-contains-any", keywords(searchInputValue))
            .where("priority", "<=", 1000) // no description tags
            .orderBy("priority").get();
      
      if (active) {
        setSearchOptions(result.docs.map((tag) => {
          const option = {
            slug: tag.get('slug'),
            title: tag.get('name'),
            priority: tag.get('priority'),
            keywords: tag.get('keywords'),
            points: 0
          };
          return option;
        }));
        setSearchOptionsLoaded(true);
      }
    })();

    return () => {
      active = false
    };
  }, [searchOptionsLoading]);
  
  React.useEffect(() => {
    if (!searchOptionsOpen) {
      setSearchOptions([]);
      setSearchOptionsLoaded(false);
    }
  }, [searchOptionsOpen]);

  React.useEffect(() => {
    if (keywords(searchInputValue).length === 0 && searchOptionsLoaded === true) {
      setSearchOptions([]);
      setSearchOptionsLoaded(false);
    }
  }, [searchInputValue, searchOptionsLoaded]);
  
  return (
    <div className={classes.search}>
      <Autocomplete
        value={search}
        open={searchOptionsOpen}
        onOpen={() => {
          setSearchOptionsOpen(true);
        }}
        onClose={() => {
          setSearchOptionsOpen(false);
        }}
        freeSolo
        autoComplete
        onChange={(event, newValue) => {
          if (newValue) {
            if (newValue.slug) {
              setSearch(newValue);
              router.push('/tags/[slug]', `/tags/${newValue.slug}`);
            }
            else {
              const value = newValue.inputValue || newValue;
              setSearch({ title: value });
              router.push({
                pathname: "/search",
                query: { q: value }
              });
            }
          }
          else {
            setSearch(newValue);
          }
        }}
        filterOptions={(options, params) => {
          let filtered = [];
          const values = keywords(params.inputValue || '');
          
          if (values.length > 0) {
            options.forEach((option) => {
              option.points = option.keywords.filter((k) => values.includes(k)).length;
              if (option.points >= values.length) {                
                const index = filtered.findIndex((f) => f.points < option.points);
                const spliceIndex = index === -1 ? filtered.length : index;
                filtered.splice(spliceIndex, 0, option);
              }              
            });
          }
          
          // Suggest the search of a free form value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Search for "${params.inputValue}"`,
            });
          }

          return filtered;
        }}        
        options={searchOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          else if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        onInputChange={(event, newInputValue) => {
          setSearchInputValue(newInputValue);
        }}
        loading={searchOptionsLoading}        
        renderOption={(option) => {
          const parts = option.inputValue ?
                [ { text: option.title, highlight: false } ] :
                parse(option.title, match(option.title, searchInputValue));
          return (
            <Typography>              
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Typography>
          );
        }}
        renderInput={(params) => {
          const { inputProps, InputProps, InputLabelProps, ...filteredParams } = params;

          const { className, ...filteredInputProps } = InputProps;

          // https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Autocomplete/Autocomplete.js
          
          return (
            <InputBase
              {...filteredParams}
              {...filteredInputProps}
              placeholder="Search"
              classes={{
                root: classes.searchInputRoot,
                input: [classes.searchInputInput, authenticated ? classes.searchInputInputWithAuth : classes.searchInputInputWithoutAuth].join(' '),
              }}
              endAdornment={(
                <React.Fragment>
                  {searchOptionsLoading ?
                   <CircularProgress color="inherit" className={classes.endAdornment} size={20} /> :
                   InputProps.endAdornment}
                </React.Fragment>)}
              inputProps={{
                ...inputProps,
                'aria-label': 'search'                   
              }}
            />
          );
        }}
      />
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
    </div>    
  );
};

export default Search;
